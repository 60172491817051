import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Import non-lazy loaded components
import Homepage from './pages/Home/Homepage.jsx';
import Page404 from './pages/404/Page404.jsx';

// Lazy load other components
const ChangePassword = lazy(() => import('./pages/ChangePassword/ChangePassword.jsx'));
const Login = lazy(() => import('./pages/Login/Login.jsx'));
const Logout = lazy(() => import('./pages/Logout/Logout.jsx'));
const RecoverPassword = lazy(() => import('./pages/RecoverPassword/RecoverPassword.jsx'));
const Register = lazy(() => import('./pages/Register/Register.jsx'));
const ValidateEmail = lazy(() => import('./pages/ValidateEmail/ValidateEmail.jsx'));

// Panel de Usuario.
const AddPet = lazy(() => import('./pages/Pet/Add.jsx'));
const EditPet = lazy(() => import('./pages/Pet/Edit.jsx'));
const PetProfile = lazy(() => import('./pages/Pet/Profile.jsx'));
const PrivateProfilePet = lazy(() => import('./pages/Pet/PrivateProfile.jsx'));

const EditUser = lazy(() => import('./pages/User/Edit.jsx'));
const UserPanel = lazy(() => import('./pages/User/Panel.jsx'));

const MedicalRecord = lazy(() => import('./pages/MedicalRecord/MedicalRecord.jsx'));
const Subscription = lazy(() => import('./pages/Subscription/Subscription.jsx'));
const PaymentHistory = lazy(() => import('./pages/PaymentHistory/PaymentHistory.jsx'));
const ActivateQR = lazy(() => import('./pages/ActivateQR/ActivateQR.jsx'));

const AdminPanel = lazy(() => import('./pages/AdminPanel/AdminPanel.jsx'));
const AdminQRs = lazy(() => import('./pages/AdminPanel/QRs/QRs.jsx'));

import './custom.css';

export default function App() {
    return (
        <Router>
            <Suspense fallback={<div></div>}>
                <Routes>
                    {/* Generales */}
                    <Route path='/' element={<Homepage />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/perfil/:token' element={<PetProfile />} />
                    <Route path='/registro' element={<Register />} />
                    <Route path='/logout' element={<Logout />} />
                    <Route path='/validar-email/:id' element={<ValidateEmail />} />
                    <Route path='/recuperar-contraseña' element={<RecoverPassword />} />
                    <Route path='/cambiar-contraseña/:token' element={<ChangePassword />} />
                    <Route path='*' element={<Page404 />} />

                    {/* Panel de Usuario */}
                    <Route path='/panel-usuario' element={<UserPanel />} />
                    <Route path='/panel-usuario/editar-perfil/:id' element={<EditUser />} />
                    
                    <Route path='/panel-usuario/agregar-mascota' element={<AddPet />} />
                    <Route path='/panel-usuario/mascota/:id' element={<EditPet />} />
                    <Route path='/panel-usuario/mascota/:id/perfil' element={<PrivateProfilePet />} />

                    <Route path='/panel-usuario/mascota/:id/ficha-medica' element={<MedicalRecord />} />
                    <Route path='/panel-usuario/suscripciones/:id' element={<Subscription />} />
                    <Route path='/panel-usuario/historial-pagos/:id' element={<PaymentHistory />} />
                    <Route path='/panel-usuario/activar-qr/:token' element={<ActivateQR />} />

                    {/* Panel de Admin */}
                    <Route path='/panel-admin' element={<AdminPanel />} />
                    <Route path='/panel-admin/qrs' element={<AdminQRs />} />
                </Routes>
            </Suspense>
        </Router>
    );
}
