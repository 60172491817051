import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Navbar as NavbarNextUI, NavbarBrand, NavbarContent, NavbarMenuToggle } from '@nextui-org/react';

import LogoFull from '../Icons/LogoFull.jsx';

import './Navbar.css';

const navLinks = [
    {
        id: 'login',
        to: '/login',
        title: 'Iniciar Sesión',
        roles: [null],
    },
    {
        id: 'register',
        to: '/registro',
        title: 'Registrarse',
        roles: [null],
    },
    {
        id: 'panelUsuario',
        to: '/panel-usuario',
        title: 'Panel de Usuario',
        roles: ['user', 'admin'],
    },
    {
        id: 'panelAdmin',
        to: '/panel-admin',
        title: 'Panel de Administrador',
        roles: ['admin'],
    },
    {
        id: 'logout',
        to: '/logout',
        title: 'Cerrar Sesión',
        roles: ['user', 'admin'],
    },
];

const mobileNavLinks = [
    {
        id: 'benefits',
        to: '#benefits',
        title: 'Beneficios',
        roles: [null, 'user', 'admin'],
    },
    {
        id: 'how-it-works',
        to: '#how-it-works',
        title: 'Cómo Funciona',
        roles: [null, 'user', 'admin'],
    },
    {
        id: 'reviews',
        to: '#reviews',
        title: 'Testimonios',
        roles: [null, 'user', 'admin'],
    },
    {
        id: 'faq',
        to: '#faq',
        title: 'Preguntas Frecuentes',
        roles: [null, 'user', 'admin'],
    },
    {
        id: 'about-us',
        to: '#about-us',
        title: 'Quiénes Somos',
        roles: [null, 'user', 'admin'],
    },
    ...navLinks,
];

Navbar.propTypes = {
    role: PropTypes.string,
};

export default function Navbar({ role }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        const handleClick = (event) => {
            if (event.target.closest('#navbar') === null) {
                setIsMenuOpen(false);
            }
        };
        
        window.addEventListener('click', handleClick);
        
        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, []);


    const handleScroll = (event, link) => {
        event.preventDefault();

        if (!link.includes('#')) {
            window.location.href = link;
        }

        const element = document.getElementById(link.substring(1));

        if (element) {
            const yOffset = -80;

            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }

        setIsMenuOpen(false);
    };

    return (
        <NavbarNextUI
            id='navbar'
            isBordered
            isMenuOpen={isMenuOpen}
            onMenuOpenChange={setIsMenuOpen}
            className='bg-primary text-white relative'
        >
            {/* Desktop Menu */}
            <NavbarContent id='desktopMenu' className='flex align-center'>
                <Link to='/'>
                    <NavbarBrand>
                        <LogoFull />
                    </NavbarBrand>
                </Link>

                <div className='flex flex-row justify-end items-center gap-3'>
                    {navLinks.map((navLink) => {
                        if (navLink.roles.includes(role)) {
                            return (
                                <Link
                                    to={navLink.to}
                                    className='underlined-link'
                                    key={navLink.id}
                                >
                                    <Button
                                        key={navLink.id}
                                        className='uppercase color-dark p-2'
                                        variant='transparent'
                                        radius='none'
                                    >
                                        {navLink.title}
                                    </Button>
                                </Link>
                            );
                        }

                        return null;
                    })}
                </div>
            </NavbarContent>

            {/* Hamburger menu */}
            <NavbarContent
                id='hamburgerMenu'
                className='color-light'
            >
                <NavbarMenuToggle
                    aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
                />
            </NavbarContent>
            
            {/* Logo mobile */}
            <NavbarContent id='logoMobile' className='flex w-full' justify='center'>
                <Link to='/' className=''>
                    <NavbarBrand>
                        <LogoFull />
                    </NavbarBrand>
                </Link>
            </NavbarContent>

            {/* Mobile */}
            <nav className={`side-nav ${isMenuOpen ? 'open' : ''}`}>
                {mobileNavLinks.map((navLink) => {
                    if (navLink.roles.includes(role)) {
                        return (
                            <a href={`/${navLink.to}`} key={navLink.id} onClick={(event) => handleScroll(event, navLink.to)}>{navLink.title}</a>
                        );
                    }

                    return null;
                })}
            </nav>
        </NavbarNextUI>
    );
}