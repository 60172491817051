import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import userService from '../../services/users.js';

import AboutUs from '../../components/AboutUs/AboutUs.jsx';
import Banner from '../../components/Banner/Banner.jsx';
import Benefits from '../../components/Benefits/Benefits.jsx';
import Reviews from '../../components/Reviews/Reviews.jsx';
import FAQs from '../../components/FAQs/FAQs.jsx';
import HowItWorks from '../../components/HowItWorks/HowItWorks.jsx';
import PageContent from '../../components/PageContent/PageContent.jsx';

import './Homepage.css';

Homepage.propTypes = {
    userRole: PropTypes.string,
    setUserRole: PropTypes.func,
};


export default function Homepage() {
    const location = useLocation();
    const [userRole, setUserRole] = useState(userService.getUserFromLocalStorage());
 
    useEffect(() => {
        const checkSession = async () => {
            try {
                const response = await userService.checkSession();

                if (response.success) {
                    setUserRole(response.data.role);
                } else {
                    setUserRole(null);
                }
            } catch (error) {
                setUserRole(null);
            }
        };

        checkSession();
    }, []);


    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));

            if (element) {
                const yOffset = -80;

                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({ top: y, behavior: 'smooth' });
            }
        }
    }, [location]);

    return (
        <div id='homepage'>
            <PageContent withBackground={false} userRole={userRole}>
                <Banner />

                <Benefits />
                
                <HowItWorks />

                <Reviews />

                <FAQs />

                <AboutUs />
            </PageContent>
        </div>
    );
}
