import axios from 'axios';
axios.defaults.withCredentials = true;

/**
 * Login request.
 *
 * @param {string} email - The user email.
 * @param {string} password - The user password.
 * @param {string} origin - The origin of the request.
 *
 * @return {object} - The response data.
 */
export const login = async (email, password, origin = null) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_ENDPOINT}login`, {
            email: email,
            password: password,
            origin: origin
        });

        if (response.data.success) {
            // Save token in local storage.
            localStorage.setItem('token', response.data.token);
        }

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};


/**
 * Register request.
 *
 * @param {string} name - The user name.
 * @param {string} lastName - The user last name.
 * @param {string} email - The user email.
 * @param {string} password - The user password.
 * @param {string} repeatPassword - The user password confirmation.
 *
 * @return {object} The response data.
 */
export const register = async (name, lastName, email, password, repeatPassword) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_ENDPOINT}register`, {
            name: name,
            lastName: lastName,
            email: email,
            password: password,
            password_confirmation: repeatPassword,
        });

        if (response.data.success) {
            // Save token in local storage.
            localStorage.setItem('token', response.data.token);
        }

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};


/**
 * Check the user session.
 *
 * @return {object} - The response data.
 */
export const checkSession = async () => {
    const pagesWithoutRedirect = ['/', '/login', '/registro', '/perfil', '/recuperar-contraseña', '/cambiar-contraseña', '/validar-email'];
    const decodedPathname = decodeURIComponent(window.location.pathname);
    const firstPart = decodedPathname.split('/')[1];

    try {
        const response = await axios.get(`${process.env.REACT_APP_ENDPOINT}check-session`);

        if (response.data.success) {
            const userSession = JSON.parse(atob(response.data.data));
            localStorage.setItem('user', response.data.data);

            return {
                success: true,
                data: {
                    id: userSession.id,
                    role: userSession.role
                }
            };
        } else {
            localStorage.removeItem('user');
            return {
                success: false
            };
        }
    } catch (error) {
        localStorage.removeItem('user');
        if (!pagesWithoutRedirect.includes(`/${firstPart}`)) {
            window.location.href = '/login';
        }
        return {
            success: false
        };
    }
};


/**
 * Logout the current user. Remove the token from local storage and redirect to the login page.
 *
 * @return void.
 */
export const logout = () => {
    try {
        const response = axios.get(`${process.env.REACT_APP_ENDPOINT}logout`);

        response.then(() => {
            // Redirect to homepage.
            window.location.href = '/';
        });
    } catch (error) {
        return error.response.data;
    }
};


/**
 * Get the user role from the local storage.
 *
 * @return {string|null} - The user role or null if the user is not logged in.
 */
export const getUserFromLocalStorage = () => {
    const userString = localStorage.getItem('user');

    if (userString) {
        try {
            const userObject = JSON.parse(atob(userString));
            return userObject.role;
        } catch (error) {
            console.error('Error parsing user from localStorage:', error);
            return null;
        }
    }

    return null;
};


/**
 * Get user profile information.
 *
 * @param {int} id - The user ID.
 *
 * @return {object} - The response data.
 */
export const getUserProfile = async (id) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}users/user-panel/${id}`);

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};


/**
 * Get user profile information.
 *
 * @param {int} id - The user ID.
 *
 * @return {object} - The response data.
 */
export const getUser = async (id) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}users/${id}`);

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};


/**
 * Update user profile information.
 *
 * @param {int} id - The user ID.
 * @param {string} name - The user name.
 * @param {string} lastName - The user last name.
 * @param {string} email - The user email.
 * @param {string} phone - The user phone.
 * @param {string} country - The user country.
 * @param {string} city - The user city.
 * @param {string} address - The user address.
 *
 * @return {object} - The response data.
 */
export const update = async (id, name, lastName, email, phone, country, city, address) => {
    try {
        const response = await axios.put(`${process.env.REACT_APP_API_ENDPOINT}users/${id}`, {
            name,
            lastName,
            email,
            phone,
            country,
            city,
            address,
        });

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};


/**
 * Send a notification to the owner of the pet, that the QR code was scanned.
 *
 * @param {string} token - The token of the pet.
 * @param {float} latitude - The latitude of the user.
 * @param {float} longitude - The longitude of the user.
 *
 * @return void.
 */
export const sendNotificationToOwner = async (token, latitude, longitude) => {
    try {
        // Send notification to the owner.
        const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}send-notification/${token}`, {
            latitude: latitude,
            longitude: longitude,
        });

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};


/**
 * Validate the email. The email must not be empty and must be in the correct format.
 *
 * @param {string} email - The email to validate.
 *
 * @return {string} - The error message.
 */
export const validateEmail = (email) => {
    if (email === null) {
        return null;
    }

    if (email === '') {
        return 'El correo electrónico es requerido.';
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        return 'El correo electrónico no es válido.';
    }

    return null;
};


/**
 * Validate the password. The password must not be empty, have at least 8 characters, at least one uppercase letter, at least one lowercase letter, and at least one number.
 *
 * @param {string} password - The password to validate.
 *
 * @return {string} - The error message.
 */
export const validatePassword = (password) => {
    if (password === null) {
        return null;
    }

    if (password === '') {
        return 'La contraseña es requerida.';
    }

    if (password.length < 8) {
        return 'La contraseña debe tener al menos 8 caracteres.';
    }

    if (!/[a-z]/.test(password)) {
        return 'La contraseña debe tener al menos una letra minúscula.';
    }

    if (!/[A-Z]/.test(password)) {
        return 'La contraseña debe tener al menos una letra mayúscula.';
    }

    if (!/[0-9]/.test(password)) {
        return 'La contraseña debe tener al menos un número.';
    }

    return null;
};


/**
 * Validate the password confirmation. The password confirmation must match the password.
 *
 * @param {string} password - The password.
 * @param {string} passwordConfirmation - The password to validate.
 *
 * @return {string} - The error message.
 */
export const validatePasswordConfirmation = (password, passwordConfirmation) => {
    if (passwordConfirmation === null) {
        return null;
    }

    if (passwordConfirmation === '') {
        return 'La confirmación de la contraseña es requerida.';
    }

    if (password !== passwordConfirmation) {
        return 'Las contraseñas no coinciden.';
    }

    return null;
};


/**
 * Validate that the field passed is not empty.
 *
 * @param {string} field - The field to validate.
 *
 * @return {string|null} - The error message or null if the field is not empty.
 */
export const validateNotEmpty = (field, message) => {
    if (field === '') {
        return message;
    }

    return null;
};


export default {
    login,
    register,
    logout,
    getUserFromLocalStorage,
    getUserProfile,
    getUser,
    update,
    sendNotificationToOwner,
    checkSession,
    validateEmail,
    validatePassword,
    validatePasswordConfirmation,
    validateNotEmpty,
};