// Import modules.
import React, { useEffect, useState } from 'react';
import { Button } from '@nextui-org/react';
import userService from '../../services/users.js';

// Import components.
import PageContent from '../../components/PageContent/PageContent.jsx';

// Import styles.
import './Page404.css';


export default function Page404() {
    // Utilities.
    const [userRole, setUserRole] = useState(null);


    useEffect(() => {
        /**
         * Check user session. The function is declared as async to use the await keyword.
         *
         * @returns {Promise<void>}
         */
        const checkSession = async () => {
            try {
                const response = await userService.checkSession();

                if (response.success) {
                    setUserRole(response.data.role);
                } else {
                    setUserRole(null);
                }
            } catch (error) {
                console.error(error);
            }
        };

        checkSession();
    }, []);

    return (
        <div id='registroPage' className='page'>
            <PageContent withBackground isAwaiting={false} userRole={userRole}>
                <div className='flex flex-col items-center'>
                    <h1 className='main-title mt-4'>Uuups!</h1>
                    <h2 className='color-primary text-center uppercase mt-4 mb-8'>La página a la cual estás queriendo acceder no existe..</h2>

                    <Button
                        color='primary'
                        className='w-full uppercase shadow-lg mt-4 mx-auto z-10'
                        radius='sm'
                        onClick={() => window.location.href = '/'}
                        style={{ maxWidth: '300px' }}
                    >
                        Volver al inicio
                    </Button>
                </div>
            </PageContent>
        </div>
    );
}
