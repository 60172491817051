import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import userService from '../../services/users.js';

import Navbar from '../Navbar/Navbar.jsx';
import MainContent from '../MainContent/MainContent.jsx';
import Footer from '../Footer/Footer';
import PawRotatedBoldIcon from '../../components/Icons/PawRotatedBoldIcon.jsx';

import './PageContent.css';

// const pagesWithoutRedirect = ['/', '/login'];

PageContent.propTypes = {
    withBackground: PropTypes.bool,
    isAwaiting: PropTypes.bool,
    userRole: PropTypes.string,
    setUserRole: PropTypes.func,
    children: PropTypes.node.isRequired,
};


export default function PageContent({ withBackground = false, isAwaiting = false, userRole, children }) {
    return (
        <>
            <Navbar role={userRole} />

            <MainContent isLoading={isAwaiting}>
                <div className='main-content-childrens relative z-10'>
                    {children}
                </div>

                {withBackground && (
                    <>
                        <PawRotatedBoldIcon className='paw-left absolute mb-4' />
                        <PawRotatedBoldIcon className='paw-right absolute mb-4' />
                    </>
                )}
            </MainContent>

            <Footer />
        </>
    );
}
